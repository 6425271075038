import { createApp } from 'vue'
import App from './App.vue'

import store from '@/store'
import router from '@/router'

import UUID from 'vue-uuid' 


import 'bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import '@fortawesome/fontawesome-free/css/all.min.css'

/** Instanciando VueJS **/
/********************** */
//Crear instancia con Root Component
const app = createApp(App);
// Carga de plugins en la instancia
app.use(router)
app.use(store)
app.use(UUID);  
// Montarla en el div #app
app.mount('#app')