import { uuid } from 'vue-uuid'

/**
 * @ ESTADOS : Estados del Store (valor que tienen)
 */

const state = {
    v_selectedYear: null,
    v_selectedCriteria: null,
    v_selectedStartDate: null,
    v_selectedFilename: null,
    v_selectedCustomFilename: null,
    v_selectedPlan: 0,
    v_isRunning: false,
    v_isFinished: false,
    v_isUploading: false,
    v_dataSet: null,
    v_dataSetEdited: null,
    v_fullDataSet: null,
    v_uuid: null,
    v_availability: {},
    v_aduccion: {},
    v_volumes: {},
    v_transfers: {},
    v_readjustments: {},
    v_capacity: {},
    v_reusePlan: {},
    v_volumesAlt: null,
    isRunning: false
}


/**
 * @ Mutaciones : Las mutaciones modifican los estados
 */
const mutations = {
    SET_SELECTED_YEAR: (state, data) => {
        state.v_selectedYear = data
    },

    SET_SELECTED_CRITERIA: (state, data) => {
        state.v_selectedCriteria = data
    },

    SET_SELECTED_START_DATE: (state, data) => {
        state.v_selectedStartDate = data
    },

    SET_SELECTED_FILENAME: (state, data) => {
        state.v_selectedFilename = data
    },

    SET_SELECTED_CUSTOM_FILENAME: (state, data) => {
        state.v_selectedCustomFilename = data
    },
    SET_SELECTED_PLAN: (state, data) => {
        state.v_selectedPlan = data
    },

    SET_IS_RUNNING: (state, data) => {
        state.v_isRunning = data
    },

    SET_IS_FINISHED: (state, data) => {
        state.v_isFinished = data
    },

    SET_IS_UPLOADING: (state, data) => {
        state.v_isUploading = data
    },

    SET_DATASET: (state, data) => {
        state.v_dataSet = data
    },
    SET_DATASET_EDITED: (state, data) => {
        state.v_dataSetEdited = data
    },
    SET_FULLDATASET: (state, data) => {
        state.v_fullDataSet = data
    },

    SET_UUID: (state, data) => {
        state.v_uuid = data
    },

    SET_AVAILABILITY: (state, data) => {
        state.v_availability = data
    },
    SET_ADUCCION: (state, data) => {
        state.v_aduccion = data
    },
    SET_ADUCCION_CELL: (state, dataCell) => {
        state.v_aduccion.data.filas[dataCell[1]].masasAgua[dataCell[2]].extraido.value = Number.parseFloat(dataCell[3]);
        state.v_aduccion.cambio = !state.v_transfers.cambio;
    },
    SET_VOLUMES: (state, data) => {
        state.v_volumes = data
    },
    SET_TRANSFERS: (state, data) => {
        state.v_transfers = data
    },
    SET_TRANSFERS_CELL: (state, dataCell) => {
        state.v_transfers.data.filas[dataCell[1]].masasAgua[dataCell[2]].extraido.value = Number.parseFloat(dataCell[3]);
        state.v_transfers.cambio = !state.v_transfers.cambio;
    },
    SET_READJUSTMENTS: (state, data) => {
        state.v_readjustments = data
    },
    SET_READJUSTMENTS_CELL: (state, dataCell) => {
        state.v_readjustments.data.filas[dataCell[1]].masasAgua[dataCell[2]].extraido.value = Number.parseFloat(dataCell[3]);
        state.v_readjustments.cambio = !state.v_transfers.cambio;
    },
    SET_TASK_STATUS: (state, data) => {
        state.isRunning = data
    },
    SET_CAPACITY: (state, data) => {
        state.v_capacity = data
    },
    SET_DATASET_ADUCCION: (state, data) => {
        state.v_dataSet.planAduccion = data
    },
    SET_DATASET_READJUSTMENTS: (state, data) => {
        state.v_dataSet.planReajustes = data
    },    
    SET_DATASET_TRANSFERS: (state, data) => {
        state.v_dataSet.planTrasvases = data
    },
    SET_DATASET_VOLUMES: (state, data) => {
        state.v_dataSet.planVolumen = data
    },    
    SET_REUSEPLAN_VOLUMES: (state, data) => {
        state.v_volumesAlt = data
        //console.log(data)
    },
    SET_REUSEPLAN_AVAILABILITY: (state, data) => {
        state.v_reusePlan.availability = data
    },
    SET_REUSEPLAN_SELECTED_START_DATE: (state, data) => {
        state.v_reusePlan.selectedStartDate = data
    },
}

/**
 * @ Acciones : Ejecutan acciones 
 */
const actions = {
    // Cambiamos los parámetros del componente de forma reactiva
    // además podemos acceder desde todos los componentes de VUE
    v_updSelectedYear({ commit }, data) {
        commit('SET_SELECTED_YEAR', data)
    },

    v_updSelectedCriteria({ commit }, data) {
        commit('SET_SELECTED_CRITERIA', data)
    },

    v_updSelectedStartDate({ commit }, data) {
        commit('SET_SELECTED_START_DATE', data)
    },

    v_updSelectedFilename({ commit }, data) {
        commit('SET_SELECTED_FILENAME', data)
    },

    v_updSelectedCustomFilename({ commit }, data) {
        commit('SET_SELECTED_CUSTOM_FILENAME', data)
    },

    v_updSelectedPlan({ commit }, data) {
        commit('SET_SELECTED_PLAN', data)
    },

    v_updIsRunning({ commit }, data) {
        commit('SET_IS_RUNNING', data)
    },

    v_updIsFinished({ commit }, data) {
        commit('SET_IS_FINISHED', data)
    },

    v_updIsUploading({ commit }, data) {
        commit('SET_IS_UPLOADING', data)
    },

    v_updDataSet({ commit }, data) {
        commit('SET_DATASET', data)
    },
    v_updDataSetEdited({ commit }, data) {
        commit('SET_DATASET_EDITED', data)
    },

    v_updFullDataSet({ commit, state, dispatch }, data) {
        commit('SET_FULLDATASET', data),
            dispatch('v_updDataSet', data[0])
    },

    v_updUUID({ commit, state }, data) {
        if (state.v_uuid == null) {
            commit('SET_UUID', uuid.v4())
        } else {
            console.log("UUID existente")
        }
    },
    v_resetUUID({ commit, state }, data) {
        commit('SET_UUID', uuid.v4())
    },

    v_updAvailability({ commit }, data) {
        commit('SET_AVAILABILITY', data)
    },
    v_updAduccion({ commit }, data) {
        commit('SET_ADUCCION', data)
    },
    v_updAduccionCell({ commit }, data) {
        commit('SET_ADUCCION_CELL', data)
    },
    v_updVolumes({ commit }, data) {
        commit('SET_VOLUMES', data)
    },
    v_updTransfers({ commit }, data) {
        commit('SET_TRANSFERS', data)
    },
    v_updTransfersCell({ commit }, data) {
        commit('SET_TRANSFERS_CELL', data)
    },
    v_updReadjustments({ commit }, data) {
        commit('SET_READJUSTMENTS', data)
    },
    v_updReadjustmentsCell({ commit }, data) {
        commit('SET_READJUSTMENTS_CELL', data)
    },
    v_setTaskIsRunning({ commit }, data) {
        commit('SET_TASK_STATUS', data)
    },
    v_updCapacity({ commit }, data) {
        commit('SET_CAPACITY', data)
    },
    v_updDataSetAduccion({ commit }, data) {
        commit('SET_DATASET_ADUCCION', data)
    },
    v_updDataSetTransfers({ commit }, data) {
        commit('SET_DATASET_TRANSFERS', data)
    },
    v_updDataSetReadjustments({ commit }, data) {
        commit('SET_DATASET_READJUSTMENTS', data)
    },
    v_updDataSetVolumes({ commit }, data) {
        commit('SET_DATASET_VOLUMES', data)
    },
    v_updDataSetReusePlanVol({ commit }, data) {   
        return new Promise((resolve, reject) => {
            commit('SET_REUSEPLAN_VOLUMES', data)
              // request succeeded
              resolve(true) // return r
          })
    },    
    v_updDataSetReusePlanAva({ commit }, data) {
        commit('SET_REUSEPLAN_AVAILABILITY', data)
    }, 
    v_updDataSetReuseSelectedStartDate({ commit }, data) {
        commit('SET_REUSEPLAN_SELECTED_START_DATE', data)
    }, 
}

/**
 * @ Getters : Llevan datos del estado al componente
 * Se usan para poder devolver datos paricales de los
 * estados, así se optimizan los datos a devolver
 * @ info: si se quiere acceder a todo los valores
 * de los estado usar mapState en vez de mapGetters
 */
const getters = {
    getDataSet(state) {
        return state.v_dataSet
    },
    getFullDataSet(state) {
        return state.v_fullDataSet
    },
    g_getReusePlan(state){
        return state.v_volumesAlt
    }
}

/**
 * @ Variables públicas
 */
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}