import Vuex from 'vuex'
import MediaVuex from '@/store/modules/MediaVuex'
import PlannerVuex from '@/store/modules/PlannerVuex'
import LoginVuex from '@/store/modules/LoginVuex'
import ToastVuex from '@/store/modules/ToastVuex'

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    media: MediaVuex,
    planner: PlannerVuex,
    login: LoginVuex,
    toast: ToastVuex
  }
})

export default store