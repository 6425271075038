<template>
  <div>
    <p class="tituloHelp">
      Esta herramienta cumple los siguientes criterios a la hora de realizar la
      "Planificación anual de Aducción":
    </p>
    <ul>
      <li>
        La aducción total se reparte entre los embalses de <i>La Minilla</i>,
        <i>El Gergal</i> y <i>Los Melonares</i>.
      </li>
      <li>
        Permite modificar la disponibilidad de los embalses, diferenciando
        entre: muy alta, alta, media, baja y nula en función del nivel de
        disponibilidad. <br /><i
          >Por ejemplo: Disponibilidad baja del embalses de el Gergal desde
          junio hasta final de año.</i
        >
      </li>
      <li>
        Permite modificar los límites de la capacidad mínima y máxima de los
        embalses. <br /><i
          >Por ejemplo: se puede establecer que el embalse de la Minilla se
          mantenga en torno al 70 %.</i
        >
      </li>
      <li>
        Se contempla que el agua que circula por los canales, ríos y conductos
        de la red no revase la capacidad máxima.
      </li>
      <li>
        Se contempla que el agua que circula por los canales,ríos y conductos de
        la red aseguren el caudal ecológico establecido en cada uno de los ríos.
      </li>

      <p class="mt-4 planoEmbalsesTitle">
        * Plano de la red de abastecimiento usado para la "Planificación de
        Aducción Inteligente"
      </p>
      <img src="~@/assets/img/embalses.png" class="w-100" />
    </ul>
  </div>
</template>


<script>
export default {
  name: "Help",
  data() {
    return {};
  },
  watch: {},
  props: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
li {
  padding: 5px;
  margin-left: 20px;
}

.planoEmbalsesTitle {
  font-size: 1rem;
  font-weight: 400;
  font-style: oblique;
}
</style>