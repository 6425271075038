<template>
  <div id="appInner">
     <Toast />
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Toast from "@/components/commons/Toast";


export default {
  name: 'App',
  components: {
     Toast,
   },
  async mounted(){
    await this.v_updUUID();
  },
  methods: {
    ...mapActions("planner", ["v_updUUID"]),
  }
}
</script>

<style>
#appInner {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
</style>
