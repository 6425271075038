<template>
  <div class="dropdown">
    <a
      class="nav-link dropdown-toggle text-light prueba"
      href="#"
      data-bs-toggle="dropdown"
    >
      <img
        src="~@/assets/img/login_avatar.png"
        class="avatar shadow rounded-circle align-middle bg-white"
      />
    </a>
    <div class="dropdown-menu dropdown-menu-end">
      <router-link
        style="text-decoration: none; color: inherit"
        :to="{ name: 'logout'}"
        class="routerLink"
      >
        <a class="dropdown-item" href="#"
          ><i class="fa-solid fa-person-running"></i> Cerrar sesión</a
        >
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userName: "",
      logged: false,
    };
  },
  methods: {},
  async mounted() {},
};
</script>

<style scoped>
i {
  margin-right: 10px;
}
.avatar {
  height: 30px;
  padding: 2px;
}
.username {
  color: #fff;
  font-size: 0.95rem;
}

.dropdown {
  float: right;
}

.dropdown-menu {
  margin-right: 1vw;
}
</style>