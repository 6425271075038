<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" :class="clase">
      <div class="modal-content">
        <div class="modal-header" :style="styleHeader">
          <h5 class="modal-title" id="exampleModalLabel" v-if="title != null">
            {{ title }}
          </h5>
          <!-- SLOT -->
          <slot name="modalHeader"></slot>
          <!-- SLOT -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :style="styleBtnClose"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div :class="frameless ? '' : 'card p-3'">
              <div :class="frameless ? '' : 'card-body'">
                <div class="row">
                  <div class="col">
                    <!-- SLOT -->
                    <slot name="modalBody"></slot>
                    <!-- SLOT -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="footer">
          <!-- SLOT -->
          <slot name="modalFooter"></slot>
          <!-- SLOT -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from "bootstrap";
export default {
  name: "Modal",
  props: {
    id: {
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    clase: {
      type: String,
      default: "modal-xl",
    },
    frameless: {
      type: Boolean,
      default: false,
    },
    styleHeader: {
      type: String,
      default: null
    },
    styleBtnClose: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closeModal(){
      let modalElelent = document.getElementById(this.id);
      let modal = Modal.getInstance(modalElelent);
      modal.hide();
    }
  },
  computed: {},
};
</script>

<style scoped>
.row {
  margin-right: 0px !important;
}
</style>