<template>
  <div>
    <div id="terminal" title="Mostrar Proceso" @click="moveToTop">
      <img
        src="~@/assets/img/terminal.svg"
      />
    </div>
  </div>
</template>


<script>
export default {
  name: "Terminal",
  data() {
    return {
      activateGoTop: false,
      scrollPosition: 0,
      parentInstance: null,
    };
  },
  watch: {},
  props: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
#terminal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.26);
  top: 125px;
  right: 21vw;
  height: 35px;
  width: 35px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  padding: 1px;
  z-index: 9;
  transition: 0.35s;
  color: #fff;
}

#terminal:hover {
  background-color: rgba(116, 114, 114, 0.8);
}
</style>