<template>
  <main
    class="
      d-flex
      flex-fill
      justify-content-center
      align-items-top
      centeredContent
      maxWrapperWidth
    "
  >
    <!-- COMPONENTE  DEL ROUTER -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- COMPONENTE  DEL ROUTER -->
  </main>
</template>

<script>
export default {
  name: "Content",
  components: { },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 1920px) {
    main {
      min-height:80vh;
    }
  }
</style>