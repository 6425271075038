/**
 * @ ESTADOS : Estados del Store (valor que tienen)
 */

 const state = {
    message: null,
    style: null,
    display: false
  }
  
  
  /**
   * @ Mutaciones : Las mutaciones modifican los estados
   */
  const mutations = {
    SET_TOAST: (state, data) => {
      state.message = data.message,
      state.style = data.style,
      state.display = !(state.display)
    }
  }
  
  /**
   * @ Acciones : Ejecutan acciones 
   */
  const actions = {
    // Cambiamos los parámetros del componente de forma reactiva
    // además podemos acceder desde todos los componentes de VUE
    showMessage({ commit }, data) {
        commit('SET_TOAST', data)
    }
  
  }
  
  /**
   * @ Getters : Llevan datos del estado al componente
   */
  const getters = {
  
  }
  
  
  /**
   * @ Variables públicas
   */
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }