/**
 * @ ESTADOS : Estados del Store (valor que tienen)
 */

 const state = {
    downloadQueue: [],
    uploadQueue: []
  }
  
  
  /**
   * @ Mutaciones : Las mutaciones modifican los estados
   */
  const mutations = {
    SET_DOWNLOAD_PROGRESS: (state, data) => {
      /* Asignacion redundante por seguridad */
      let dataStruct = {
        id: data.id,
        downloadProgress: data.downloadProgress,
        downloadLoaded: data.downloadLoaded,
        downloadTotal: data.downloadTotal
      }
  
      /* Lo metemos en el array de control solo si no existe*/
      let index = state.downloadQueue.findIndex(x => x.id == dataStruct.id);
      if (index === -1) {
        // Lo metemos en el array en caso de que no exista
        state.downloadQueue.push(dataStruct)
      } else {
        state.downloadQueue[index].downloadProgress = dataStruct.downloadProgress
        state.downloadQueue[index].downloadLoaded = dataStruct.downloadLoaded
        state.downloadQueue[index].downloadTotal = dataStruct.downloadTotal
      }
  
    },
  
    REM_DOWNLOAD_PROGRESS: (state, data) => {
      /* Buscamos en el array previamente populado para borrar la coincidencia */
      let index = state.downloadQueue.findIndex(x => x.id == data.id);
      if (index === -1) {
        // Si no existe coincidencia salimos
        return;
      } else {
        state.downloadQueue.splice(index, 1);
      }
  
    },
  
  
    SET_UPLOAD_PROGRESS: (state, data) => {
      /* Asignacion redundante por seguridad */
      let dataStruct = {
        id: data.id,
        uploadLoaded: data.uploadLoaded,
        uploadTotal: data.uploadTotal
      }
  
      /* Lo metemos en el array de control solo si no existe*/
      let index = state.uploadQueue.findIndex(x => x.id == dataStruct.id);
      if (index === -1) {
        // Lo metemos en el array en caso de que no exista
        state.uploadQueue.push(dataStruct)
      } else {
        state.uploadQueue[index].uploadLoaded = dataStruct.uploadLoaded
        state.uploadQueue[index].uploadTotal = dataStruct.uploadTotal
      }
  
    },
  
    REM_UPLOAD_PROGRESS: (state, data) => {
      /* Buscamos en el array previamente populado para borrar la coincidencia */
      let index = state.uploadQueue.findIndex(x => x.id == data.id);
      if (index === -1) {
        // Si no existe coincidencia salimos
        return;
      } else {
        state.uploadQueue.splice(index, 1);
      }
  
    }
  
  }
  
  /**
   * @ Acciones : Ejecutan acciones 
   */
  const actions = {
    // Cambiamos los parámetros del componente de forma reactiva
    // además podemos acceder desde todos los componentes de VUE
    addToDownloadQueue({ commit }, data) {
      commit('SET_DOWNLOAD_PROGRESS', data)
    },
  
    removeFromDownloadQueue({ commit }, data) {
      commit('REM_DOWNLOAD_PROGRESS', data)
    },
  
    addToUploadQueue({ commit }, data) {
      commit('SET_UPLOAD_PROGRESS', data)
    },
  
    removeFromUploadQueue({ commit }, data) {
      commit('REM_UPLOAD_PROGRESS', data)
    }
  
  }
  
  /**
   * @ Getters : Llevan datos del estado al componente
   */
  const getters = {
  
  }
  
  
  /**
   * @ Variables públicas
   */
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }