import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: {
      beforeRouteEnter(to, from, next) {
        return next({ name: 'login' });
      }
    }
  },
  {
    path: '/login',
    meta: {
      breadCrumb: 'login'
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'login',
        // usar el metodo component:() para lazy loading y reducir peso web
        component: () => import('@/views/login'),
        beforeEnter: async (to, from, next) => {
          // Si no hay datos en vuex, redirigmos a la primera pantalla de calculo
          let hasToken = await store.getters["login/getToken"]
          if (hasToken != null) {
            return next({ name: 'wizard-step-0' });
          } else {
            return next()
          }
        },        
        meta: {
          title: 'Login',
          guest: true
        }
      }
    ]

  },
  {
    path: '/logout',
    meta: {
      breadCrumb: 'Cerrar sesión'
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'logout',
        component: {
          async beforeRouteEnter(to, from, next) {
            // Si no hay datos en vuex, redirigmos a la primera pantalla de calculo
            store.dispatch('login/v_closeSession')
            return await next({ name: 'login' })
          }
        },
        meta: {
          title: 'Logout',
          guest: false
        }
      }
    ]

  },
  {
    path: '/wizard',
    meta: {
      breadCrumb: 'Configurador asistido'
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'wizard-step-0',
        component: () => import('@/views/wizard'),
        meta: {
          title: 'Planificar asistido - Opciones',
          guest: false
        }
      },
      {
        path: '/newplan/preconfigure',
        name: 'wizard-step-1',
        component: () => import('@/views/wizard/newPlan/step1-preconfigure'),
        meta: {
          title: 'Planificar asistido - Preconfiguración',
          guest: false
        }
      },
      {
        path: '/newplan/solutions',
        name: 'wizard-step-2',
        component: () => import('@/views/wizard/newPlan/step2-solutions'),
        beforeEnter: async (to, from, next) => {
          // Si no hay datos en vuex, redirigmos a la primera pantalla de calculo
          let hasDataSet = await store.getters["planner/getDataSet"]
          if (hasDataSet == null) {
            return next({ name: 'wizard-step-1' });
          } else {
            return next()
          }
        },
        meta: {
          title: 'Planificar asistido - Posibles soluciones',
          guest: false
        }
      },
      {
        path: '/newplan/bestplan',
        name: 'wizard-step-3',
        component: () => import('@/views/wizard/newPlan/step3-bestplan'),
        beforeEnter: async (to, from, next) => {
          // Si no hay datos en vuex, redirigmos a la primera pantalla de calculo
          let hasDataSet = await store.getters["planner/getDataSet"]
          if (hasDataSet == null) {
            return next({ name: 'wizard-step-1' });
          } else {
            return next()
          }
        },
        meta: {
          title: 'Planificar asistido - Solución óptima',
          guest: false
        }
      },
    ]
  },
  {
    path: '/favoriteplan',
    meta: {
      breadCrumb: 'Usar plan aducción favorito',
    },
    component: Layout,
    children: [
      {
        path: '',
        name: 'favorite-plan',
        component: () => import('@/views/wizard/favoritePlan'),
        meta: {
          title: 'Planificar asistido - Plan favorito',
          guest: false
        }
      }]
  }
]

/* Crar historial de rutas ***/
/****************************/
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})


/* Acciones a realizar antes de entrar en cada ruta */
/****************************************************/
router.beforeEach(async (to, from, next) => {
  //Obtener token en caso de que lo tenga
  const hasToken = await store.getters["login/getToken"]


  //Si está marcadado como una página NO protegida
  if (to.meta.guest === true) {
    return next()
  } else {

    if (Boolean(hasToken) != true) {
      return next({ name: 'login' });
    } else {
      return next()
    }
  }


});


export default router
