<template>
  <footer>
    <div class="offSet">
      <div class="row text-center">
        <div class="col">
          <a href="https://www.us.es" target="_blank"
            ><img
              src="~@/assets/logos/logo_us_principal.svg"
              class="logoUS d-none d-sm-inline"
              title="Universidad de Sevilla"
          /></a>
        </div>
      </div>
      <div class="row mt-2">
        <a
          href="mailto:carvajal@us.es"
          class="text-reset text-decoration-none align-self-center pt-2"
        >
          <p class="text-center">
            V.2023.06.10 <br />
            © Grupo de Ingeniería Electrónica <br />Escuela Técnica Superior de
            Ingeniería <br />
            Camino de los descubrimientos S/N, 41092, Sevilla
            <br />
          </p>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>


<style scoped>
footer {
  font-size: 0.8rem;
  color: white;
  background-color: #1b68a5;
  border-top: 1px solid rgba(0, 0, 0, 0.0625);
  transition: 0.3s;
  box-shadow: 0px 0 8px rgb(0 0 0 / 20%);
}

.logoUS {
  height: auto;
  width: 120px;
  filter: grayscale(100%) drop-shadow(3px 3px 3px rgb(0 0 0 / 0.5));
  opacity: 0.9;
  transition: 0.3s;
}

.logoUS:hover {
  filter: drop-shadow(3px 3px 3px rgb(0 0 0 / 0.5));
}

.offSet {
  position: relative;
  top: -30px;
}
</style>

