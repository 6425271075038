<template>
  <!-- INICIO DEL LAYOUT PRINCIPAL -->
  <div class="bg-common">
    <div class="wrapper">
      <!-- HEADER -->
      <header-layout />
      <!-- HEADER-->

      <!-- CONTENIDO -->
      <content-layout />
      <!-- CONTENIDO -->

      <!-- FOOTER -->
      <footer-layout />
      <!-- FOOTER-->

      <!-- BUTTOn TO GO UP -->
      <go-to-top />
      <!-- BUTTOn TO GO UP -->
    </div>
  </div>
  <!-- FIN DEL LAYOUT PRINCIPAL -->
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import Terminal from "@/components/terminal";
import GoToTop from "./components/GoToTop.vue";

export default {
  data() {
    return {
      applyBlur: false,
    };
  },
  components: {
    "header-layout": Header,
    "content-layout": Content,
    "footer-layout": Footer,
    terminal: Terminal,
    "go-to-top": GoToTop,
  },
  methods: {},
};
</script>

<style lang="css">
@import "~@/assets/css/main.css";
</style>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bg-common {
  position: relative;
}

.bg-common::before {
  content: "";
  background-image: url("~@/assets/logos/emasesa_back.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 120%;
  position: absolute;
  opacity: 0.06;
  top: -500px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
</style>