<template>
  <div>
    <!-- Modal Ayudar -->
    <Modal
      id="mainHelpModal"
      title="Ayuda"
      :frameless="true"
      :footer="false"
      :altmode="true"
      styleHeader="background: #305680; color: #fff;"
      styleBtnClose="filter: brightness(100) invert(1);"
      ref="mainHelpModal"
    >
      <template v-slot:modalBody>
        <HelpList ref="helpList" />
      </template>
    </Modal>

    <header
      class="
        d-flex
        navbar navbar-dark
        sticky-top
        flex-md-nowrap
        p-0
        shadow
        titleNavBar
        justify-content-center
      "
      id="headerNavBar"
    >
      <!-- Logo y titulo -->
      <router-link
        class="cursor-pointer"
        style="text-decoration: none; color: inherit"
        :to="{ name: 'wizard-step-0' }"
        title="Ir al inicio"
      >
        <img
          style="height: 3rem"
          src="~@/assets/logos/emasesa_simple.svg"
          class="logoEmasesa p-2 me-2 mt-2 mb-2 d-none d-sm-inline"
          title="Emasesa Metropolitana"
        />
        <!-- Title -->
        <span class="titleHeader align-middle"
          >Planificador de Aducción Inteligente</span
        >
      </router-link>
      <!-- HOME icon -->
      <router-link
        class="cursor-pointer"
        style="text-decoration: none; color: inherit"
        :to="{ name: 'wizard-step-0' }"
        title="Ir al inicio"
      >
        <img
          style="height: 2rem; margin-left: 18vw"
          src="~@/assets/img/home.png"
          class="
            shadow
            rounded-circle
            align-middle
            bg-white
            d-none d-sm-inline
            p-1
            cursor-pointer
          "
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Ir al inicio"
          v-if="v_auth"
        />
      </router-link>
      <!-- Ayuda -->
      <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ayuda">
        <img
          style="height: 2rem; margin-left: 1vw"
          src="~@/assets/img/help.svg"
          class="
            shadow
            rounded-circle
            align-middle
            bg-white
            d-none d-sm-inline
            p-1
            cursor-pointer
          "
          data-bs-toggle="modal"
          data-bs-target="#mainHelpModal"
          v-if="v_auth"
        />
      </span>
      <!-- DROPDOWN -->
      <dropdown-layout
        class="drowpDownMenu d-none d-md-inline ms-2"
        v-if="v_auth"
      />
    </header>
    
  </div>
</template>


<script>
import { Tooltip } from "bootstrap";
import { mapState, mapActions } from "vuex";
import Modal from "@/components/commons/Modal";
import Dropdown from "./Dropdown";
import HelpList from "@/components/help";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("login", ["v_auth"]),
  },
  components: {
    "dropdown-layout": Dropdown,
    Modal,
    HelpList
  },
  mounted() {
    //inti tooltip
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
      (tooltipNode) => new Tooltip(tooltipNode)
    );
  },
};
</script>

<style scoped>
.titleNavBar {
  min-height: 65px;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  background-color: #1b68a5;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
}

.titleHeader {
  color: #fff;
  font-size: min(2.1rem, calc(100% + 1vw + 1vh));
  font-weight: 500;
}

.logoEmasesa {
  /* CSS de mejora aqui */
  /* Este añade soombrita */
  filter: drop-shadow(3px 3px 3px rgb(0 0 0 / 0.2));
}

/*.logoEmasesa {
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 15s linear infinite;
    -moz-animation:spin 15s linear infinite;
    animation:spin 15s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}*/
</style>