import { setUserToken, getUserToken, removeUserToken } from '@/utils/auth';

/**
 * @ ESTADOS : Estados del Store (valor que tienen)
 */

const state = {
    v_auth: getUserToken(),
}


/**
 * @ Mutaciones : Las mutaciones modifican los estados
 */
const mutations = {
    SET_IS_AUTH: (state, data) => {
        state.v_auth = data
    },
}
/**
 * @ Acciones : Ejecutan acciones 
 */
const actions = {
    // Cambiamos los parámetros del componente de forma reactiva
    // además podemos acceder desde todos los componentes de VUE
    v_setAuth({ commit }, data) {
        setUserToken(data)
        commit('SET_IS_AUTH', data)
    },

    v_closeSession({ commit }) {
        localStorage.removeItem('TokenKey') 
        commit('SET_IS_AUTH', null)
    }
}


/**
 * @ Getters : Llevan datos del estado al componente
 * Se usan para poder devolver datos paricales de los
 * estados, así se optimizan los datos a devolver
 * @ info: si se quiere acceder a todo los valores
 * de los estado usar mapState en vez de mapGetters
 */
const getters = {

    getToken(state) {
        return state.v_auth
    },
}

/**
 * @ Variables públicas
 */
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}